import { T } from "@/locales";

export const rules = {
  number: [
    { required: true, message: T("请输入编号"), trigger: "change" },
    { max: 32, message: T("超出最大长度 (32)"), trigger: "change" },
  ],
  name: [
    { required: true, message: T("请输入货主名称"), trigger: "change" },
    { max: 64, message: T("超出最大长度 (64)"), trigger: "change" },
  ],
  warehouse: [{ required: true, message: T("请选择仓库"), trigger: "change" }],
  contacts: [{ max: 32, message: T("超出最大长度 (32)"), trigger: "change" }],
  phone: [{ max: 32, message: T("超出最大长度 (32)"), trigger: "change" }],
  email: [{ max: 256, message: T("超出最大长度 (256)"), trigger: "change" }],
  address: [{ max: 256, message: T("超出最大长度 (256)"), trigger: "change" }],
  remark: [{ max: 256, message: T("超出最大长度 (256)"), trigger: "change" }],
};
